@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.SharingOption {
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    .SharingOption-badge--default {
      background: $palette--tint-3-grey;
      border: 1px solid $palette--tint-4-grey;
    }
    .SharingOption-badge--facebook {
      background: #deebfd;
      border: 1px solid #d3e4fc;
    }
    .SharingOption-badge--linkedin {
      background: #dbeaf2;
      border: 1px solid #cfe4ee;
    }
    .SharingOption-badge--twitter {
      background: #ddf1fd;
      border: 1px solid #d2ecfc;
    }
    .SharingOption-badge--instagram {
      background: linear-gradient(
        204.44deg,
        #e2e7fb 15.63%,
        #f2e1ef 36.27%,
        #ffdddd 56.32%,
        #fee7e2 71.43%,
        #fff3e3 85.05%
      );
      border: 1px solid;
      border-image-source: linear-gradient(
        205.75deg,
        #d9dffa 9.55%,
        #eed7ea 34.53%,
        #ffd2d2 51.98%,
        #fddfd9 69.04%,
        #feefda 86.38%
      );
    }
    .SharingOption-badge--whatsapp {
      background: #e5f3e4;
      border: 1px solid #ddeedb;
    }
    .SharingOption-badge--messenger {
      background: radial-gradient(
        109% 108.99% at 19.25% 99.44%,
        #deecff 0%,
        #efe2ff 61%,
        #ffe5ec 100%
      );
      border: 1px solid;
      border-image-source: linear-gradient(
        203.03deg,
        #ffdfe3 14.91%,
        #ead7ff 49.88%,
        #d2e7ff 86.96%
      );
    }
    .SharingOption-badge--telegram {
      background: #dff2fc;
      border: 1px solid #d4eefb;
    }
  }
}

.SharingOption-badge--default {
  background: $palette--tint-2-grey;
  border: 1px solid $palette--tint-3-grey;
}
.SharingOption-badge--facebook {
  background: #e9f2fd;
  border: 1px solid #deebfd;
}
.SharingOption-badge--linkedin {
  background: #e7f1f6;
  border: 1px solid #dbeaf2;
}
.SharingOption-badge--twitter {
  background: #e8f6fe;
  border: 1px solid #ddf1fd;
}
.SharingOption-badge--instagram {
  background: linear-gradient(
    205.75deg,
    #eceffc 9.55%,
    #f6ebf5 34.53%,
    #ffe8e8 51.98%,
    #feefec 69.04%,
    #fff7ec 86.38%
  );
  border: 1px solid;
  border-image-source: linear-gradient(
    205.75deg,
    #e2e7fb 9.55%,
    #f2e1ef 34.53%,
    #ffdddd 51.98%,
    #fee7e2 69.04%,
    #fff3e3 86.38%
  );
}
.SharingOption-badge--whatsapp {
  background: #eef7ed;
  border: 1px solid #e5f3e4;
}
.SharingOption-badge--messenger {
  background: radial-gradient(
    109% 108.99% at 19.25% 99.44%,
    #e9f3ff 0%,
    #f5ebff 61%,
    #ffeff1 100%
  );
  border: 1px solid;
  border-image-source: linear-gradient(
    203.03deg,
    #ffe5ec 14.91%,
    #efe2ff 49.88%,
    #deecff 86.96%
  );
}
.SharingOption-badge--telegram {
  background: #eaf6fd;
  border: 1px solid #dff2fc;
}

.SharingOption-link {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
}

.SharingOption-badgeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 60px;
  height: 60px;

  @include gt(small) {
    width: 46px;
    height: 46px;
  }
}

.SharingOption-label {
  @include typo--body-2;
  margin-top: $spacing--base;

  @include gt(small) {
    @include typo--body-3;
    color: $palette--dark-grey;
  }
}

.SharingOption--inDrawer {
  .SharingOption-icon {
    scale: 1.3;
  }

  .SharingOption-badgeContainer {
    width: 60px;
    height: 60px;
  }
}
