@import "../../styles/variables.scss";

.Badge-Icon {
  margin-left: 9px;
}

.Badge--servicedApartments {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  left: 20px;
  top: 15px;
  z-index: 2;
  cursor: default;

  &-infoIcon {
    cursor: pointer;
  }

  &--popover-button {
    margin-top: 20px;

    > .IconButton-label {
      margin-left: 0;
    }
  }

  &--popoverInner {
    width: auto;
  }

  &-drawer {
    height: 100%;
  }

  &-drawerBody {
    height: 100%;
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-buttons {
      margin-top: 30px;
    }
  }

  &--popper-reference {
    margin-left: 10px;
    width: 18px;
    height: 18px;

    .popper-InnerReference {
      height: 18px;
    }
  }
}
