@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.TenantServiceFeeInfoPopOver-toggleButton {
  cursor: pointer;
  background: none;
  outline: none;
  border: 0;
  font-family: unset;
  font-size: unset;
  color: unset;
}

.TenantServiceFeeInfoPopOver-content {
  padding: 20px;
  padding-bottom: 0;

  @include gt(medium) {
    width: 330px;
    padding: 0;
  }
}

.TenantServiceFeeInfoPopOver-contentInfo {
  font-weight: $font-weight--medium;
  font-size: $font-size--f;
  line-height: normal;
  color: $palette--dark-grey;
  word-break: break-word;

  & .TenantServiceFeeInfoPopOver-linkContainer {
    display: block;
    margin-top: 10px;

    & a {
      color: $palette--wunder-brand-purple;
      text-decoration: underline;
    }
  }
}

.TenantServiceFeeInfoPopOver-gotItButtonContainer {
  margin-top: 30px;
  width: 100%;
}
