@import "../../styles/variables.scss";
@import "../../styles/media.scss";
@import "../../styles/typography.scss";

.SimilarListings {
  background: $palette--tint-1-grey;
  padding: ($spacing--base * 6) ($spacing--base * 3);

  @include gt(medium) {
    padding: 70px 15px;
  }

  .ListingsList {
    max-width: $global-width--max-width;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;

    @include gt(medium) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .ListingsList-item {
    width: 300px;
    @include gt(medium) {
      max-width: 330px;
      flex-basis: 30%;
    }
    .Listing {
      border: none;
      box-shadow:
        0px 4.366px 4.366px 0px rgba(59, 55, 86, 0.03),
        0px 8.733px 17.466px 0px rgba(55, 57, 86, 0.1);
    }
  }
}

.SimilarListings-title {
  @include typo--heading-3;
  margin-bottom: 30px;
  text-align: center;
}
