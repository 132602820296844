@import "../../styles/variables/typography.scss";
@import "../../styles/variables.scss";

.FlexibleDaysAvailability {
  @include typo--body-2;
  border-top: $border-new-line-separator;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 30px;
}

.FlexibleDaysAvailability-duration {
  margin-left: 9px;
}
