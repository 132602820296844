@import "../../styles/variables.scss";
@import "../../styles/media.scss";

$cardWidth: 300px;

.ListingsList {
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(auto-fit, minmax($cardWidth, 1fr));

  @include gt(medium) {
    gap: 27px;
  }
  .Listings & {
    min-height: 100vh;
  }
}

.ListingsList-item {
  min-width: $cardWidth;

  .CoverImage {
    border-radius: 7px 7px 0 0;
  }
}

.ListingsList-title {
  font-size: $font-size--g;
  color: $palette--grey;
  line-height: 1.5;
  margin-bottom: $spacing--base * 3;
  min-width: 100%;
}

.ListingsList-title-text {
  font-size: $font-size--g;
  font-weight: $font-weight--regular;
  display: inline;
}
