@import "../../../styles/variables.scss";

.DateRangePickerMobile-drawer-children {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DateRangePickerMobile-root {
  padding-top: 85px;
  border: none;
  width: max-content;

  &--bottom {
    padding: 40px;
  }
}

.DateRangePickerMobile-months {
  display: flex;
  flex-direction: column;
}

.DateRangePickerMobile-month {
  margin: 0px;
}

.DateRangePickerMobile-caption {
  padding-top: 25px;
  padding-bottom: 20px;
  text-align: center;
}

.DateRangePickerMobile-head {
  display: none;
}

.DateRangePickerMobile-nav {
  display: none;
}

.Button.DateRangePickerMobile-loadMoreButton {
  margin-bottom: 150px;
  overflow: visible;
}
