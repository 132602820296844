@import "../../styles/variables.scss";

.Badge {
  display: inline-block;
  text-transform: uppercase;
  color: white;
  font-family: $primary-font-stack;
  font-style: normal;
  font-weight: $font-weight--bold;
  font-size: $font-size--g;
  line-height: 18px;
  border: none;
  border-radius: $spacing--base;
  background-color: $palette--grey;
  width: max-content;
  padding: 4px 10px 3px 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}

.Badge--flex {
  display: flex;
}

.Badge--brand {
  background-color: $palette--wunder-purple;
}

.Badge--primary {
  background-color: $palette--lime-green;
}

.Badge--grey {
  background-color: $palette--grey;
}

.Badge--silver {
  background-color: $palette--silver;
}

.Badge--white-transparent {
  background-color: $color-bg-white-transparent;
}

.Badge--color-text {
  color: $color-text;
}

.Badge--silverOutline {
  background-color: $palette--tint-1-grey;
  border: 1px solid $palette--silver;
  color: $palette--silver;
}

.Badge--successOutline {
  background-color: rgba($palette--lime-green, 0.2);
  border: 1px solid $palette--lime-green;
  color: $palette--lime-green;
}

.Badge--brandOutline {
  background-color: rgba($palette--wunder-brand-purple, 0.2);
  border: 1px solid $palette--wunder-brand-purple;
  color: $palette--wunder-brand-purple;
}

.Badge--warningOutline {
  background-color: $palette--pale-orange;
  border: 1px solid $palette--warning-orange;
  color: $palette--warning-orange;
}
