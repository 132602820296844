@import "../../../styles/variables.scss";

.DatePickerStickyHeader {
  position: fixed;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $palette--pure-white;
    padding-top: $spacing--base;
    padding-bottom: $spacing--base * 2;
    border-bottom: $border-line-separator;
  }

  &-title {
    @include typo--label-text;
    color: $palette--black;
  }

  &-dates {
    @include typo--body-2;
    color: $palette--silver;

    &--active {
      color: $palette--dark-grey;
    }
  }

  &-weekdays {
    display: flex;
    @include typo--body-2;
    color: $palette--grey;
    padding-top: $spacing--base * 3;

    &-day {
      width: $spacing--base * 8;
      text-align: center;
    }
  }

  &-clearDatesButton {
    margin-top: 10px;
    box-shadow: $box-shadow-floatingButton;
  }
}
