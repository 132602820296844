@import "../../styles/media.scss";
@import "../../styles/variables.scss";

.ImageGallery {
  position: relative;
  height: 100%;
  text-align: center;
}

.ImageGallery-container {
  &.ImageGallery-container--touch {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
  }
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: calc(20vh + 50px);
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;

  @media all and (orientation: portrait) {
    background-size: 100% auto;
  }

  @media all and (orientation: landscape) {
    background-size: auto 100%;
  }
}

.ImageGallery-imageDesktop {
  height: calc(100% - #{$spacing--medium});
}

.ImageGallery-image {
  height: calc(100% - #{$spacing--medium});

  &.ImageGallery-image--touch {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    scroll-snap-align: center center;
    scroll-snap-stop: always;
    flex-shrink: 0;
  }

  & img,
  &.ImageGallery-image--touch img {
    max-width: 100%;
    border-radius: 0.1px;
    object-fit: cover;
    max-height: calc(100% - #{$spacing--medium});
  }
}

.ImageGallery-caption {
  color: white;
  font-weight: $font-weight--bold;
}

.ImageGallery-thumbnails {
  position: absolute;
  bottom: $spacing--small;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  height: 20vh;
  z-index: 2;

  @media screen and (orientation: landscape) {
    bottom: 0;
  }

  .ImageGallery-thumbnail {
    display: inline-block;
    background: #000;
    cursor: pointer;
    margin-right: 10px;
  }

  .ImageGallery-thumbnail img {
    opacity: 0.55;
    height: 20vh;
    width: auto;
  }

  .ImageGallery-thumbnail:hover img {
    opacity: 0.6;
  }

  .ImageGallery-thumbnail--active,
  .ImageGallery-thumbnail--active:hover {
    img {
      opacity: 1;
    }
  }

  .ImageGallery-thumbnail:last-child {
    margin-right: 0;
  }
}

@media only screen and (max-height: 736px) {
  .ImageGallery-container {
    top: 20px;
    bottom: calc(10vh + 20px);
  }

  .ImageGallery-thumbnails,
  .ImageGallery-thumbnails .ImageGallery-thumbnail img {
    height: 10vh;
  }
}

.ImageGallery-navButton {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  width: 200px;
  height: 100%;

  z-index: 1;
  position: absolute;
  top: 0;

  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 36px;
    height: 36px;
    opacity: 0.8;
    transition: all 0.1s ease-in-out;
  }

  &:hover > img {
    opacity: 1;
    transform: scale(1.1);
  }

  &*::selection {
    background-color: transparent;
  }

  &--left {
    left: 0;
  }

  &--right {
    right: 0;
  }
}

.ImageGallery-navButtonsOuter {
  @include lt(xsmall) {
    display: none;
  }
}

.ImageGallery-galleryImage {
  position: relative;
}

.ImageGallery-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  animation: appear 3s ease-in;
  animation-delay: 6000ms;
}

.ImageGallery-lazyLoad {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;

  @media screen and (orientation: landscape) {
    width: auto;
    height: 100%;
  }
}
