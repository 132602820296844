html {
  font-size: 16px;
}

body {
  font-family: $primary-font-stack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include ms-font-size(0);
  color: $color-text;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.link {
  &--primary {
    color: $color-brand;

    &:hover,
    &:focus {
      color: $color-brand-dark;
    }
  }

  &--danger {
    color: $color-danger;

    &:hover,
    &:focus {
      color: darken($color-danger, 15);
    }
  }

  &--dark {
    color: $color-text;

    &:hover,
    &:focus {
      color: darken($color-text, 15);
    }
  }
}

.font-size--a {
  font-size: $font-size--a;
}

.font-size--b {
  font-size: $font-size--b;
}

.font-size--c {
  font-size: $font-size--c;
}

.font-size--d {
  font-size: $font-size--d;
}

.font-size--e {
  font-size: $font-size--e;
}

.font-size--f {
  font-size: $font-size--f;
}

.font-weight--light {
  font-weight: $font-weight--light;
}

.font-weight--regular {
  font-weight: $font-weight--regular;
}

.font-weight--bold {
  font-weight: $font-weight--bold;
}

.heading-jumbo {
  @include ms-font-size(7);
  line-height: $line-height--jumbo;
  color: $color-text;
  margin: 0 auto $spacing--medium;

  @include lt(small) {
    @include ms-font-size(5);
    line-height: $line-height--large;
  }

  &--light {
    color: $color-text-light;
  }

  &--brand {
    color: $color-brand;
  }

  &--centered {
    text-align: center;
  }
}

.subheading-jumbo {
  @include ms-font-size(2);
  line-height: $line-height--medium;
  color: $color-text-light;
  font-weight: $font-weight--regular;
  margin: $spacing--medium auto;
  text-align: center;

  @include lt(small) {
    @include ms-font-size(1);
  }
}

.heading-large {
  @include ms-font-size(5);
  line-height: $line-height--large;
  color: $color-text;
  margin: 0 0 $spacing--medium;
  font-weight: $font-weight--regular;

  @include lt(small) {
    @include ms-font-size(4);
    line-height: $line-height--large;
  }

  &--light {
    color: $color-text-light;
  }

  &--brand {
    color: $color-brand;
  }

  &--centered {
    text-align: center;
  }

  &--bold {
    font-weight: $font-weight--bold;
  }
}

.heading-medium {
  @include ms-font-size(1);
  font-family: $primary-font-stack;
  line-height: $line-height--medium;
  margin: 0 0 $spacing--small;
}

.heading-small {
  @include ms-font-size(1);
  font-weight: $font-weight--regular;
  line-height: $line-height--medium;
  margin: 0 0 $spacing--small;

  &--light {
    color: $color-text-light;
  }

  &--brand {
    color: $color-brand;
  }

  &--centered {
    text-align: center;
  }
}

.heading-tiny {
  @include ms-font-size(0);
  font-weight: $font-weight--regular;
  line-height: $line-height--medium;
  margin: 0 0 $spacing--micro;

  &--light {
    color: $color-text-light;
  }

  &--brand {
    color: $color-brand;
  }

  &--centered {
    text-align: center;
  }
}

.heading-large-group {
  :first-child {
    margin-bottom: $spacing--small;
  }

  margin-bottom: $spacing--large;
}

.form-title {
  @include ms-font-size(0);
  font-weight: $font-weight--regular;
  line-height: $line-height--medium;
  margin-top: $spacing--medium;
  margin-bottom: $spacing--small;
}

.form-subtitle {
  display: block;
  color: $color-brand;
  padding: 0;
  font-weight: $font-weight--regular;
  @include ms-font-size(minus1);
}

.form-subtitle-new {
  @include ms-font-size(0);
  display: block;
  padding: 0;
  margin-top: 30px;
  margin-bottom: 20px;
  font-family: $primary-font-stack;
  font-weight: $font-weight--regular;
}

.text-small {
  @include ms-font-size(minus2);
  line-height: $line-height--micro;
}

.text-muted {
  color: $color-text-light-gray;
  margin: $spacing--small auto;
  line-height: $line-height--small;
}
