@import "../../styles/variables.scss";

.StudentExclusivePill {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  left: 20px;
  top: 15px;
  z-index: 1;
  background: rgba(36, 39, 46, 0.45);
  border: 1px solid rgba(255, 255, 255, 0.45);
  border-radius: $border-radius--small;
  font-family: $primary-font-stack;
  font-style: normal;
  font-weight: $font-weight--medium;
  font-size: $font-size--g;
  line-height: 18px;
  color: $color-text-light;
  padding: $spacing--base $spacing--base * 2;
  pointer-events: none;

  &--short {
    height: 18px;
    padding: 10px 7px;
    top: 12px;
    left: 15px;
  }
}
