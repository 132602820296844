@import "../../../styles/variables.scss";

.DateRangePickerTrigger-popover {
  box-shadow: $box-shadow-medium;
}

.DateRangePickerTriggerPill {
  @include typo--body-2;
  background-color: $palette--pure-white;
  border-radius: 20px;
  padding: 3px;
  margin-right: 10px;
  cursor: pointer;

  &:has(> .DateRangePickerTriggerPill-previewDateButton--active),
  &:has(> .DateRangePickerTriggerPill-previewDateButton--selected),
  &:has(> .DateRangePickerTriggerPill-previewDateButton--cleared) {
    border: 2px solid rgba($color: $palette--wunder-brand-purple, $alpha: 0.45);
    border-radius: 100px;
  }
}

.DateRangePickerTrigger-footer {
  background: $palette--pure-white;
  border-top: $border-line-separator;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding-top: 20px;
  margin: 0 25px 5px;
  @include typo--body-2;

  &--mobile {
    position: fixed;
    padding: 15px 30px;
    z-index: 1000;
    bottom: 0;
    margin: 0;
    width: 100%;

    &-duration {
      padding: 25px 30px;
    }
  }
}

.DateRangePickerTriggerPill-previewDateButton {
  background-color: $palette--pure-white;
  border: 1px solid $palette--tint-3-grey;
  border-radius: 100px 0px 0px 100px;
  cursor: pointer;
  padding: 8px 15px;
  @include typo--body-2;
  min-width: 125px;

  &:nth-of-type(2) {
    border-radius: 0px 100px 100px 0px;
  }

  &--active {
    background-color: $palette--pale-purple;
    border: 1px solid $palette--wunder-brand-purple;
    color: $palette--wunder-brand-purple;
  }

  &--inactive {
    &:hover {
      background: $palette--tint-2-grey;
      border: 1px solid $palette--tint-4-grey;
      color: $palette--dark-grey;
    }
  }

  &--selected,
  &--applied {
    background-color: $palette--wunder-brand-purple;
    border: 1px solid $palette--wunder-brand-purple;
    color: $palette--pure-white;

    &:nth-of-type(2) {
      border: 1px solid $palette--wunder-brand-purple;
      border-left-color: $palette--pure-white;
    }

    &:hover {
      background-color: $palette--dark-purple;
      border: 1px solid $palette--wunder-brand-purple;
      color: $palette--pure-white;
    }
  }
}

.DateRangePickerTriggerPill-ctaPanel {
  justify-content: space-between;
}

.SearchBarDateRangePicker-ctaPanel {
  justify-content: center;
}

.ListingsFilter-DateRangePicker {
  .Drawer-inner {
    min-height: 100%;
  }
}

.DateRangePickerTrigger-clearDates.Button.Button--growWithText {
  margin-left: -20px;
}

.DatePickerContent {
  overflow: hidden;
}
