@import "../../../../styles/variables.scss";
@import "../../../../styles/media.scss";

.PermitIdComponentPopover-closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
}

.PermitIdComponentPopover-content {
  padding: 20px;

  @include gt(medium) {
    width: 335px;
    padding: 0;
  }
}

.PermitIdComponentPopover-contentTitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: $font-weight--bold;
  font-size: $font-size--d;
  color: $palette--black;
  margin-bottom: 25px;
}

.PermitIdComponentPopover-contentInfo {
  font-weight: $font-weight--medium;
  font-size: $font-size--f;
  line-height: $line-height--small;
  color: $palette--black;
  margin-bottom: $spacing--small;
  word-break: break-word;
}

.PermitIdComponentTitlePopover-toggleButton {
  display: flex;
  align-items: center;

  cursor: pointer;

  background: none;
  outline: none;
  border: 0;
  margin-bottom: 10px;

  & h4 {
    @include typo--heading-4;
    color: $palette--black-gray;
  }

  & svg {
    margin-left: 10px;
  }
}

.PermitIdComponentBodyPopover-toggleButton {
  cursor: pointer;

  background: none;
  outline: none;
  border: 0;

  text-decoration: underline;
  font-family: Poppins;
  font-weight: $font-weight--medium;
  font-size: $font-size--e;
  color: $palette--dark-grey;

  max-width: 90vw;
  text-align: left;
}

.PermitIdComponentBodyPopover-permitIdContainer {
  margin-top: 15px;
  margin-bottom: 30px;
  font-size: $font-size--f;
  font-weight: $font-weight--medium;
  color: $palette--black;
  max-height: 300px;
  overflow-y: auto;
}

.PermitIdComponentBodyPopover-seperator {
  border: 0;
  border-top: 1px solid $palette--tint-3-grey;
  margin-bottom: $spacing--small;
}

.MoreInformationLink {
  margin-top: 5px;
  display: block;
  text-decoration: underline;
  font-size: $font-size--e;
  color: $palette--dark-grey;
}

.PermitIdComponentBodyPopover-moreInformationLink {
  font-size: $font-size--f;
}
