@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.SharingPopover {
  @include gt(medium) {
    width: 300px;
  }
}

.SharingPopover-title {
  @include typo--heading-4;
  text-align: left;
  padding: 0 0 $spacing--base * 5 0;
}

.SharingPopover-title--inDrawer {
  text-align: center;
  padding: $spacing--small $spacing--base * 6 $spacing--base * 6;
}

.SharingPopover-options {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "..." "..." "...";
  gap: 25px 15px;
  padding: 0 $spacing--base * 9 $spacing--base * 5 $spacing--base * 9;

  @include gt(medium) {
    gap: $spacing--base * 4 12px;
    padding: 0 0 0 0;
  }
}
