@import "../../styles/variables.scss";

.Button.ShareButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ShareButton-icon {
  margin-right: $spacing--base;
}
